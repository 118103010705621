import React from 'react';
import './Plans.css';
import { plansData } from '../../data/plansData';
import whiteTick from '../../assets/whiteTick.png';

const Plans = () => {
    return (
        <div className="plane-container" id='plan'>
            <div className="blur plan-blur-l"></div>
            <div className="blur plan-blur-r"></div>
            <div className="program-header">
                <span className='stroke-text'>READY TO START</span>
                <span>OUR JOURNEY</span>
                <span className='stroke-text'>NOW WITHUS</span>
            </div>

            <div className="plans">
                {
                    plansData.map((plan, i) => (
                        <>
                            <div className="plane" key={i}>
                                {plan.icon}
                                <span>{plan.name}</span>
                                <span>$ {plan.price}</span>

                                <div className="features">
                                    {
                                        plan.features.map((feature, i) => (
                                            <div className="feature">
                                                <img src={whiteTick} alt="" />
                                                <span key={i}>{feature}</span>
                                            </div>
                                        ))
                                    }
                                </div>

                                <div>
                                    <span>See More Benefits  -</span>
                                </div>
                                <button className="btn">Join Now</button>
                            </div>
                        </>
                    ))
                }


            </div>
        </div>
    )
}

export default Plans